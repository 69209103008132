import "bootstrap";
import "./scss/app.scss";
import "owl.carousel";

/* Font Awesome */
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)
dom.i2svg()     // automatically find any <i> tags in the page and replace those with <svg> elements

/* Wait for page to load - https://css-tricks.com/making-animations-wait/ */
document.body.classList.add('js-loading');

window.addEventListener("load", showPage);

function showPage() {
    document.body.classList.remove('js-loading');
}

$(document).ready(function() {
    // update with latest year
    $('#year').html(new Date().getFullYear());
})